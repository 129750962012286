/* eslint-disable react/display-name */
import React from "react"
import { graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"

import SideCTA from "../components/CTA/SideCTA"
import Heading from "../components/Hero/Heading"
import { SmallCTA } from "../components/CTA"

const SingleInstruction = ({ data, pageContext, location }) => {
  const { language, metaDescription, metaTitle } = pageContext
  const post = data.allInstructionsJson.nodes[0]

  const queryUrl = "/" + data.queryPage.nodes[0].title

  const translations = data.translations.nodes[0]

  return (
    <Layout className="single-instruction" language={"en"}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        youtubeSchemas={pageContext.youtubeSchemasJson}
        lang={language}
      />
      <Heading
        sideColumnsSize={4}
        heading={post.name}
        htmlBefore={
          <Link className="go-back-button mb-1 block" to={queryUrl}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <span style={{ marginLeft: "5px" }}>
              {translations.back[language]}
            </span>
          </Link>
        }
      />

      <div className="body-section joshua-tree-content instruction-content">
        {post.youtube && post.mainPhotoPublicId && (
          <div className="columns instruction-image-video">
            <div className="column is-3" />
            <div className="column">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                overVideo
                language={language}
                buttonClass="contained">
                <Image
                  publicId={post.mainPhotoPublicId}
                  useAR
                  className="theater-image"
                />
              </TheaterVideo>
            </div>
            <div className="column is-3" />
          </div>
        )}
        <div className="columns single-instruction-content">
          <div className="column is-3" />
          <div className="column">
            <Text
              useStringReplace
              className="p-with-ul single-instructions-ul"
              text={post.body}
            />
          </div>

          {post.pdfId && (
            <>
              <div className="column is-2" />
              <div className="column is-6 here-to-help">
                {post.pdfId && (
                  <SideCTA
                    className="single-instruction__side-cta-wrapper"
                    sideColumnIs={0}
                    subheading={
                      language === "es" ? "Versión PDF" : "PDF Version"
                    }
                    heading={
                      language === "es"
                        ? "Instrucciones de Descarga"
                        : "Download Instructions"
                    }
                    paragraph={
                      language === "es"
                        ? "Sigue las instrucciones sobre la marcha. Descargar en formato PDF."
                        : "Take the instructions on the go. Download in PDF format."
                    }
                    buttonText={language === "es" ? "Descargar" : "Download"}
                    buttonUrl={`https://res.cloudinary.com/nuvolum/image/upload/v1/${post.pdfId}.pdf`}
                    download
                  />
                )}
              </div>
            </>
          )}
          <div className="column is-3" />
        </div>
      </div>
      <SmallCTA />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($title: String!, $language: String) {
    allUniquePagesJson(filter: { template: { eq: "patient-instructions" } }) {
      nodes {
        title
      }
    }
    queryPage: allUniquePagesJson(
      filter: {
        template: { eq: "patient-instructions" }
        language: { eq: $language }
      }
    ) {
      nodes {
        title
      }
    }
    allInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    translations: allTranslationsJson {
      nodes {
        back {
          en
          es
        }
      }
    }
  }
`

export default SingleInstruction
